h1 {
  margin-top:0px;
}

html {
  position: relative;
  height: 100%
}

body {
  position: relative;
  min-height: 100%;
  padding-bottom:31px;
}

#tpl-content {
  position: relative;
  padding-bottom:10px;
}

#tpl-footer {
  position:absolute;
  bottom: 0;
  width: 100%;
  margin:0;
  z-index: 2000;
}

.footer {
  padding:8px;
  background: #555555;
  text-align: center;
  color:#FFFFFF;
  font-size: 10px;
}

.navbar .navbar-nav .active{
  background: linear-gradient(white, #ddd 40%, #e4e4e4);
}

.form .has-error small.error {
  color: #FF4136;
  font-style: italic;
}
.form-delete {
  display:inline;
}
.nowrap {
  white-space: nowrap;
}

.form-group.required .control-label:after {
  content:"*";
  color:red;
}

.delivery-group-inactive label {
  color:#898989;
}

.panel-inline .panel-heading {
  overflow: hidden;
}

.panel-inline .panel-body {
  overflow: hidden;
  height: 0;
  padding: 0 15px;
}

.row-flex {
  display: flex;
  align-items: center;
}

.pagination {
  margin:10px 0;
}

.pagination-info{
  margin:10px 0 10px 8px;
  color:#858585;
}

.table {
  margin-bottom: 10px;
}

.well hr {
  border-top:solid 1px #dddddd;
}

.green {
  color: #0F9D58;
}

.red {
  color: #FF3333;
}

textarea.form-control {
  resize:none;
}

.no-header{
  margin-top:20px;
}

.row-action {
  margin-bottom: 3px;
}

/* vertical line divider */
.border-right, .border-left, .border-both {
  position: relative;
}

.border-left:before, .border-right:after {
  position: absolute;
  content: '';
  height: auto;
  top: 0;
  bottom: 0;
  border-left: 1px solid #c3c3c3;
  width: 0;
}

.border-left:before {
  left: 0;
}

.border-right:after {
  right: -1px;
}

.event-action {
  z-index:2001;
}